import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import Endpoint from "./types/endpoint";
import Zapier from "./types/zapier";
import Blackbaud from "./types/blackbaud/blackbaud";
import Virtuous from "./types/virtuous/virtuous";
import Bloomerang from "./types/bloomerang/bloomerang";
import Neon from "./types/neon/neon";
import DonorPerfect from "./types/donorPerfect/donorPerfect";
import DonorConnect from "./types/donorConnect/donorConnect";
import PlanningCenter from "./types/planningCenter/planningCenter";
import Kindful from "./types/kindful/kindful";
import Advance from "./types/advance/advance";
import Graduway from "./types/graduway/graduway";
import Salesforce from "./types/salesforce/salesforce";
import Slate from "./types/slate/slate";
import { getAccount, getAccountUsers } from "services/api.service";

export default function Integration(props) {
  let { integrationId } = props;
  let [state, setState] = useState({});
  let [accountInfo, setAccountInfo] = useState({});
  let [data, setData] = useState("loading");
  let [users, setUsers] = useState("loading");

  let integrationDetailsJSON = {
    stripe: {
      integrationId: "stripe",
      type: "endpoint",
      logoSrc: "/static/img/logos/stripeLogoWhite.png",
      brandHex: "#6A73D9",
      header: "Stripe Integration",
      desc: "Automatically create task and/or send notes when a Stripe invoice is paid",
      stepOneDesc: (
        <>
          Follow the directions using our{" "}
          <a
            href="https://medium.com/@will_44872/how-to-connect-gratavid-and-stripe-ed270091dc40?source=friends_link&sk=77067cf7499859ffa2e83120d4b182f8"
            target="_blank"
            rel="noopener noreferrer">
            step by step guide to connect your Gratavid and Stripe account (click here)
          </a>{" "}
          using your endpoint url below.
        </>
      ),
      endpointURLNoKey:
        "https://api.gratavid.com/integrationsEndpoint?accountUID=" +
        props.accountInfo.id +
        "&source=stripe&event=invoicePaymentSucceeded&apiKey=",
    },
    zapier: {
      integrationId: "zapier",
      type: "zapier",
      logoSrc: "/static/img/logos/zapierLogo.png",
      brandHex: "#F1F1F1",
      header: "Zapier Integration",
      desc: "Integrate with over 2,000+ apps powered by Zapier",
    },
    bloomerang: {
      integrationId: "bloomerang",
      type: "bloomerang",
      logoSrc: "/static/img/logos/bloomerangIcon.png",
      header: "Bloomerang Integration",
      desc: "Automatically setup triggers and actions from Bloomerang events",
    },
    neon: {
      integrationId: "neon",
      type: "neon",
      logoSrc: "/static/img/logos/neonOneIcon.png",
      header: "Neon One Integration",
      desc: "Automatically setup triggers and actions from Neon One events",
    },
    donorPerfect: {
      integrationId: "donorPerfect",
      type: "donorPerfect",
      logoSrc: "/static/img/logos/donorPerfectIcon.png",
      header: "DonorPerfect Integration",
      desc: "Automatically setup triggers and actions from DonorPerfect events",
    },
    kindful: {
      integrationId: "kindful",
      type: "kindful",
      logoSrc: "/static/img/logos/kindfulIcon.png",
      header: "Kindful Integration",
      desc: "Automatically setup triggers and actions from Blackbaud events",
    },
    blackbaud: {
      integrationId: "blackbaud",
      type: "blackbaud",
      logoSrc: "/static/img/logos/blackbaudLogo.png",
      header: "Blackbaud Integration",
      desc: "Automatically setup triggers and actions from Blackbaud events",
    },
    planningCenter: {
      integrationId: "planningCenter",
      type: "planningCenter",
      logoSrc: "/static/img/logos/planningCenter.png",
      header: "Planning Center Integration",
      desc: "Automatically setup triggers and actions from Planning Center events",
    },
    salesforce: {
      integrationId: "salesforce",
      type: "salesforce",
      logoSrc: "/static/img/logos/salesforce.svg",
      header: "Salesforce Integration",
      desc: "Automatically setup triggers and actions from Salesforce events",
    },
    slate: {
      integrationId: "Slate",
      type: "slate",
      logoSrc: "/static/img/logos/slateIcon.png",
      header: "Slate Integration",
      desc: "Automatically setup triggers and actions from Slate events",
    },
    virtuous: {
      integrationId: "virtuous",
      type: "virtuous",
      logoSrc: "/static/img/logos/virtuousIcon.png",
      header: "Virtuous Integration",
      desc: "Automatically setup triggers and actions from Virtuous events",
    },
    advance: {
      integrationId: "advance",
      type: "advance",
      logoSrc: "/static/img/logos/advanceIcon.png",
      header: "Advance Integration",
      desc: "Automatically setup triggers and actions from Advance events",
    },
    graduwayEngage: {
      integrationId: "graduwayEngage",
      type: "graduwayEngage",
      logoSrc: "/static/img/logos/graduwayIcon.png",
      header: "Graduway Integration",
      desc: "Automatically setup triggers and actions from Graduway events",
    },
    donorConnect: {
      integrationId: "donorConnect",
      type: "donorConnect",
      logoSrc: "/static/img/logos/donorConnectIcon.png",
      header: "Donor Connect Integration",
      desc: "Automatically send videos when calls are completed",
    },
  };

  useEffect(() => {
    let getData = async () => {
      try {
        const account = await getAccount(props.accountInfo.id);
        setAccountInfo(account);

        const endpoint = account.integrations?.endpoint?.[props.integrationId];

        const users = await getAccountUsers();
        setUsers(users.map(({ id, email }) => ({ email, userUID: id })));
        setData(endpoint ?? {});
      } catch (err) {
        console.error(err.code);
        let errorMessage =
          err.code === "PERMISSION_DENIED"
            ? "PERMISSION_DENIED. Must be an admin to view and edit integrations."
            : err.message;
        setState({ errorMessage });
      }
    };

    getData();
  }, [props.accountInfo.id, props.integrationId]);

  if (data === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        {state.errorMessage ? (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        ) : (
          <LinearProgress />
        )}
      </div>
    );

  let { apiKey, active } = data;
  let integrationDetails = integrationDetailsJSON[integrationId];

  if (!apiKey && !active)
    return (
      <p>
        <a
          href="https://graduwayhelp.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener noreferrer">
          Submit a request/ticket
        </a>{" "}
        to receive your integration api keys. Make sure to tell us which platform you want
        to integrate with (ie - Zapier, Stripe, etc.)
      </p>
    );
  else if (integrationDetails && integrationDetails.type === "zapier")
    return (
      <Zapier
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
      />
    );
  else if (integrationDetails && integrationDetails.type === "blackbaud")
    return (
      <Blackbaud
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "bloomerang")
    return (
      <Bloomerang
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "donorPerfect")
    return (
      <DonorPerfect
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "neon")
    return (
      <Neon
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "kindful")
    return (
      <Kindful
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "salesforce")
    return (
      <Salesforce
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "planningCenter")
    return (
      <PlanningCenter
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "virtuous")
    return (
      <Virtuous
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "advance")
    return (
      <Advance
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "graduwayEngage")
    return (
      <Graduway
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "donorConnect")
    return (
      <DonorConnect
        data={data}
        apiKey={apiKey}
        accountInfo={accountInfo}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );
  else if (integrationDetails && integrationDetails.type === "slate")
    return (
      <Slate
        accountInfo={accountInfo}
        integration={data}
        apiKey={apiKey}
        integrationDetails={integrationDetails}
        users={users}
        userInfo={props.userInfo}
      />
    );

  return (
    <Endpoint
      data={data}
      accountInfo={accountInfo}
      users={users}
      integrationDetails={integrationDetails}
    />
  );
}
Integration.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    integrations: PropTypes.any,
  }),
  userInfo: PropTypes.object.isRequired,
  integrationId: PropTypes.string.isRequired,
};

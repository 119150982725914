import React, { useState, useRef } from "react";
import {
  Button,
  Tooltip,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import CopyEmbedCode from "../../../../../util/shareOptions/copyEmbedCode";
import ScheduleDialog from "./scheduleDialog";
import PreviewOrEmbedByContact from "../../../../../util/shareOptions/previewOrEmbedByContact";
import { sendNote } from "services/api.service";

export default function SendTask(props) {
  let {
    taskId,
    noteId,
    userId,
    videoIds = [],
    shareViaEmail,
    shareViaText,
    textEnabled,
    videoIdsProcessing = [],
    accountInfo,
    userEmailProvider = "",
    activeContactCountForTask,
  } = props;

  const firstContactId = props.currentTask.taskContacts[0]?.contactId;
  let firstVideoId = videoIds && videoIds[0] && videoIds[0].videoId;
  let firstVideoData =
    props.taskVideos?.length && props.taskVideos[0].video
      ? props.taskVideos[0].video
      : {};
  let firstVideoReady = firstVideoData && firstVideoData.videoUrl ? true : false;
  let [state, setState] = useState({});
  let [sendClickedOnce, setSendClickedOnce] = useState(false);
  let [scheduledialogOpen, setScheduledialogOpen] = useState(false);
  let [sendOptionOpen, setSendOptionOpen] = useState(false);
  const anchorRef = useRef(null);

  let sendTask = async () => {
    try {
      if (!taskId) {
        throw new Error("Missing task. Must delete task and start over.");
      }

      if (!noteId) {
        throw new Error("No note selected");
      }

      if (!videoIds || videoIds.length === 0) {
        throw new Error("No video selected");
      }

      if (!shareViaEmail && !shareViaText) {
        throw new Error(
          textEnabled
            ? "Must select send by email and/or text"
            : "Must select send by email"
        );
      }

      setState({ buttonState: "loading" });

      await sendNote({
        noteId,
        taskId,
        gvSendId: `sid_${taskId}`,
        status: "processing",
        calledFrom: "editNote",
        contactIds: props.currentTask.taskContacts.map(
          (taskContact) => taskContact.contactId
        ),
        type: "sendNote",
        emailProvider: userEmailProvider,
        shareViaEmail,
        shareViaText,
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.sendTask" });

      const taskIndex = props.tasks.findIndex((task) => task.id === props.taskId);
      props.tasks[taskIndex].sentByUserId = userId;
      props.setTasks([...props.tasks]);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage });
    }
  };

  if (!noteId) {
    return null;
  }

  let readyToSend = true;
  let sendDisabledLabel = "";
  if (videoIdsProcessing.length > 1) {
    readyToSend = false;
    sendDisabledLabel = "Please wait. Processing video...";
  } else if (!firstVideoReady) {
    readyToSend = false;
    sendDisabledLabel = "Please wait. Processing video...";
  } else if (activeContactCountForTask !== props.taskContacts.length) {
    readyToSend = false;
    sendDisabledLabel = "Remove deleted contacts before sending.";
  } else if (activeContactCountForTask === 0) {
    readyToSend = false;
    sendDisabledLabel = "Must add at least one active contact under 'Send video to'";
  } else if (activeContactCountForTask > 100) {
    readyToSend = false;
    sendDisabledLabel = "Max contacts exceeded. Must be less than 100.";
  }

  if (readyToSend)
    return (
      <div className="row" style={{ flex: 1, margin: "5px 0px" }}>
        <div style={{ marginRight: "15px" }}>
          <span style={{ marginRight: "10px" }}>
            {sendClickedOnce ? (
              <LoadingButton
                variant="contained"
                color="secondary"
                buttonState={state.buttonState}
                savedLabel={"Sent!"}
                label={"Click to Send"}
                clickedRegularButton={() => sendTask()}
                clickedSavedButton={() => {
                  return null;
                }}
              />
            ) : (
              <>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  ref={anchorRef}
                  aria-label="send now options">
                  <Button onClick={() => setSendClickedOnce(true)}>Send Now</Button>
                  <Button
                    style={{ width: "30px", minWidth: "30px" }}
                    aria-controls={sendOptionOpen ? "send-options-menu" : undefined}
                    aria-expanded={sendOptionOpen ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => setSendOptionOpen(!sendOptionOpen)}>
                    <i className="material-icons">arrow_drop_down</i>
                  </Button>
                </ButtonGroup>
                <Popper
                  open={sendOptionOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: "1301" }}
                  placement="top-end">
                  <Paper style={{ zIndex: "1302" }}>
                    <ClickAwayListener onClickAway={() => setSendOptionOpen(false)}>
                      <MenuList id="split-button-menu">
                        <MenuItem
                          button
                          style={{ width: "168px" }}
                          onClick={() => {
                            setScheduledialogOpen(true);
                            setSendOptionOpen(false);
                          }}>
                          <i className="material-icons" style={{ marginRight: "5px" }}>
                            schedule_send
                          </i>{" "}
                          Schedule Send
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </>
            )}
          </span>

          {activeContactCountForTask === 1 ? (
            <>
              <CopyEmbedCode
                videoData={firstVideoData}
                videoId={firstVideoId}
                noteId={noteId}
                contactId={firstContactId}
                taskId={taskId}
                accountInfo={props.accountInfo}
              />
              <Button>
                <a
                  href={
                    `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
                    noteId +
                    "&tId=" +
                    taskId +
                    "&cId=" +
                    firstContactId +
                    "&preview=true&showPreviewNotification=1"
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  Preview{" "}
                  <i className="material-icons" style={{ fontSize: "12px" }}>
                    open_in_new
                  </i>
                </a>
              </Button>
            </>
          ) : (
            <>
              <PreviewOrEmbedByContact
                type="embed"
                title="Embed Video By Contact"
                noteId={noteId}
                accountInfo={accountInfo}
                videoData={firstVideoData}
                videoId={firstVideoId}
                taskContacts={props.taskContacts}
              />
              <PreviewOrEmbedByContact
                type="preview"
                title="Preview By Contact"
                noteId={noteId}
                taskId={taskId}
                contentId={null}
                accountInfo={accountInfo}
                taskContacts={props.taskContacts}
              />
            </>
          )}
        </div>

        {scheduledialogOpen && (
          <ScheduleDialog
            {...props}
            fullScreen={props.fullScreen}
            closeDialog={() => setScheduledialogOpen(false)}
            dialogOpen={true}
          />
        )}
      </div>
    );

  return (
    <div className="row col-12" style={{ marginTop: "15px" }}>
      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="row" style={{ flex: 1, margin: "5px 0px" }}>
        <div style={{ marginRight: "15px" }}>
          <Tooltip title={sendDisabledLabel} enterTouchDelay={0}>
            <Button
              variant="contained"
              style={{
                color: "rgba(0, 0, 0, 0.26)",
                fontWeight: 600,
                marginRight: "10px",
              }}>
              Send
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
